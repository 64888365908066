<template>
  <v-app id="app" full-height>
    <no-js />
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NoJs from "@/components/elements/NoJs.vue";

export default defineComponent({
  components: { NoJs },
});
</script>
